@mixin border-radius {
  border-radius: 5px;
}

@mixin icomoon-settings {
  text-align: center;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin box-shadow {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
}

$color-blue: #29A9FC;

.booking-wrapper{
  margin: 0 40px;
  background: #fff;
  @include border-radius;
  font-size: 16px;
  color: #000;
  overflow: hidden;

  h2 {
    font-family: 'Open Sans';
    font-weight: 800 !important;
    font-size: 30px !important;
    line-height: 1.1em;
    padding: 5px 0 5px 0 !important;
    color: #000 !important;
  }

  .text-blue {
    color: $color-blue;
    font-weight: 700;
    margin-bottom: 25px;
  }
  .booking-content {
    padding: 25px;
    background: url(../../Belvg_Customer/images/customer/trainingsplan/images/testphase.jpg) right bottom no-repeat;
    background-size: 300px auto;
  }
  .checklist {
    list-style-type: none;
    padding: 0;
    margin: 0 0 10px 0;;

    li {
      padding-left: 20px;
      position: relative;

      &:before {
        @include icomoon-settings;
        content: "\edfb";
        color: $color-blue;
        position: absolute;
        left: 0;
        top: 4px;
        padding-bottom: 5px;
      }
    }
  }
  .btn {
    @include box-shadow;
    color: white;
  }
  .btn-wrapper {
    text-align: center;
  }
  .user-stats {
    color: #fff;
  }
  .user-stats {
    padding: 12px 0 6px 0;
    text-align: center;
    background: #009df0;
    background: -moz-linear-gradient(left, #009df0 0%, #20dcff 100%);
    background: -webkit-linear-gradient(left, #009df0 0%, #20dcff 100%);
    background: linear-gradient(to right, #009df0 0%, #20dcff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#009df0', endColorstr='#20dcff',GradientType=1);
    color: #fff;

    .user-stats-count {
      font-family: 'Open Sans';
      font-weight: 800;
      font-size: 20px;
      padding-bottom: 5px;
    }
    .user-stats-desc {
      font-size: 12px;
      position: relative;
      top: -6px;
      line-height: 14px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .booking-wrapper{
    margin: 0 10px;
  }
}

.access-upgrade {
  color: #fff;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}